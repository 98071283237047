import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import * as serviceWorker from "./serviceWorkerRegistration";
import axios from "axios";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// 모든 Axios 요청에 대해 전역적으로 설정을 적용합니다.
// axios.defaults.baseURL = 'https://yourdomain.com/api';
axios.defaults.withCredentials = process.env.REACT_APP_WITH_CREDENTIALS === "true";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.timeout = 60 * 1000; // 모든 요청에 대해 타임아웃 설정

let WindowW = window.innerWidth;
let WindowH = window.innerHeight;
document.documentElement.style.setProperty("--app-width", "".concat(String(WindowW), "px"));
document.documentElement.style.setProperty("--app-height", "".concat(String(WindowH), "px"));

window.onresize = function () {
  let WindowW = window.innerWidth;
  let WindowH = window.innerHeight;
  document.documentElement.style.setProperty("--app-width", "".concat(String(WindowW), "px"));
  document.documentElement.style.setProperty("--app-height", "".concat(String(WindowH), "px"));
};

root.render(
  <RecoilRoot>
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <App />
      </Router>
    </Suspense>
  </RecoilRoot>
);

serviceWorker.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
