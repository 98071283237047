import styled from "@emotion/styled";
import DescriptionIcon from "@mui/icons-material/Description";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BarChartIcon from "@mui/icons-material/BarChart";
import ShareIcon from "@mui/icons-material/Share";
import DownloadIcon from "@mui/icons-material/Download";

const ReportComponent: React.FC = () => {
  return (
    <Container>
      {/* <TitleContainer>
        <h1>수업준비</h1>
      </TitleContainer> */}
      <ContentContainer>
        <LeftContainer>
          <img src="/images/intro/sheet.png" alt="워크시트" />
        </LeftContainer>
        <RightContainer>
          <TextTitleContainer>
            <StyledDescriptionIcon />
            <h1>워크시트</h1>
          </TextTitleContainer>
          <TextContent>
            단어, 문장, 묻고답하기를
            <br />
            객관식/주관식 등 다양한 유형으로 제공해드립니다.
          </TextContent>
        </RightContainer>
      </ContentContainer>
      <ContentContainer>
        <LeftContainer>
          <img src="/images/intro/report.png" alt="리포트" />
        </LeftContainer>
        <RightContainer>
          <TextTitleContainer>
            <StyledBarChartIcon />
            <h1>리포트</h1>
          </TextTitleContainer>
          <TextContent>
            학습한 데이터를 실시간으로
            <br />
            수집해서 아이들의 학습현황을 보여드립니다.
          </TextContent>
        </RightContainer>
      </ContentContainer>
      <ContentContainer>
        <LeftContainer>
          <img src="/images/intro/books.png" alt="교재" />
        </LeftContainer>
        <RightContainer>
          <TextTitleContainer>
            <StyledPictureAsPdfIcon />
            <h1>스토리북</h1>
          </TextTitleContainer>
          <TextContent>
            스토리북 pdf파일
            <br />
            가이드북 제공
          </TextContent>
        </RightContainer>
      </ContentContainer>
      <ContentContainer>
        <LeftContainer>
          <img src="/images/intro/message.png" alt="영상공유" />
        </LeftContainer>
        <RightContainer>
          <TextTitleContainer>
            <StyledShareIcon />
            <h1>영상 공유</h1>
          </TextTitleContainer>
          <TextContent>
            원클릭으로 아이들이 더빙한 영상
            <br />
            부모님께 공유
          </TextContent>
        </RightContainer>
      </ContentContainer>
      <ContentContainer>
        <LeftContainer>
          <img src="/images/intro/roleplay.png" alt="소품" />
        </LeftContainer>
        <RightContainer>
          <TextTitleContainer>
            <StyledDownloadIcon />
            <h1>역활극 소품</h1>
          </TextTitleContainer>
          <TextContent>
            역활극 활동에 필요한
            <br />
            캐릭터 및 배경 이미지 제공
          </TextContent>
        </RightContainer>
      </ContentContainer>
    </Container>
  );
};

export default ReportComponent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  margin: 0 auto;
`;

const TitleContainer = styled.div`
  font-size: 34px;
  line-height: 50px;
  font-weight: 600;
  word-break: keep-all;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

const ContentContainer = styled.div`
  width: 98%;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

const LeftContainer = styled.div`
  width: 70%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const RightContainer = styled.div`
  width: 30%;
  margin-left: 5rem;

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
`;

const StyledDescriptionIcon = styled(DescriptionIcon)`
  font-size: 60px;
  color: #1976d2; /* 파란색 */

  @media (max-width: 768px) {
    font-size: 50px;
  }

  @media (max-width: 480px) {
    font-size: 40px;
  }
`;

const StyledBarChartIcon = styled(BarChartIcon)`
  font-size: 60px;
  color: #388e3c; /* 초록색 */

  @media (max-width: 768px) {
    font-size: 50px;
  }

  @media (max-width: 480px) {
    font-size: 40px;
  }
`;

const StyledPictureAsPdfIcon = styled(PictureAsPdfIcon)`
  font-size: 60px;
  color: #d32f2f; /* 빨간색 */

  @media (max-width: 768px) {
    font-size: 50px;
  }

  @media (max-width: 480px) {
    font-size: 40px;
  }
`;

const StyledShareIcon = styled(ShareIcon)`
  font-size: 60px;
  color: #fbc02d; /* 노란색 */

  @media (max-width: 768px) {
    font-size: 50px;
  }

  @media (max-width: 480px) {
    font-size: 40px;
  }
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  font-size: 60px;
  color: #1976d2; /* 파란색 */

  @media (max-width: 768px) {
    font-size: 50px;
  }

  @media (max-width: 480px) {
    font-size: 40px;
  }
`;

const TextTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin: 0 auto;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const TextContent = styled.p`
  margin-top: 20px;
  font-weight: bold;
  color: #696969;
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
  }
`;
