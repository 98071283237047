import styled from "@emotion/styled";

export const MenuContainer = styled.header`
  position: fixed;
  z-index: 11;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 95px;
  background-color: #fff;
`;
export const MainSection = styled.div`
  margin-top: 95px;

  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

export const MainInnerContainer = styled.div`
  width: 100%;
  min-width: 360px;
  position: relative;
  overflow: hidden;
  text-align: center;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const MainTextContainer = styled.div`
  width: 100%;
  margin: 0px auto;
  max-width: 1240px;
  padding: 200px 0px;
  position: relative;

  @media (max-width: 1024px) {
    padding: 150px 0px;
  }

  @media (max-width: 768px) {
    padding: 100px 0px;
  }

  @media (max-width: 480px) {
    padding: 50px 0px;
  }
`;

export const MainImg = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0px;
  object-fit: cover;
  object-position: 50% 100%;
  color: transparent;
`;

const Section = styled.div`
  padding: 10rem 0;
  min-height: 20vh;

  @media (max-width: 1024px) {
    padding: 8rem 0;
  }

  @media (max-width: 768px) {
    padding: 6rem 0;
  }

  @media (max-width: 480px) {
    padding: 4rem 0;
  }
`;

// 세션별 스타일
export const Section1 = styled(Section)`
  background-color: #fafafa;
`;

// 세션별 스타일
export const Section2 = styled(Section)`
  background-color: #fff;
`;

// 세션별 스타일
export const Section3 = styled(Section)`
  background-color: #efecf6;
`;

export const InnerContainer = styled.div`
  width: 1240px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 1240px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const InnerContainer2 = styled.div`
  width: 1380px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 1240px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const TextContainer = styled.div`
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }
`;
