import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const MenuComponent: React.FC = () => {
  return (
    <MenuContainer>
      <LogoLink to="/">
        <Image src="/images/logo2.jpg" alt="로고" />
      </LogoLink>
      <div>
        <Link2 to="https://taesanuhak1.modoo.at/">방학캠프/유학</Link2>
        <Link1 to="/teacher/login">로그인</Link1>
      </div>
    </MenuContainer>
  );
};

export default MenuComponent;

const MenuContainer = styled.div`
  width: 1240px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1240px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 209px;
  height: 79px;

  @media (max-width: 768px) {
    width: 150px;
    height: 56px;
  }

  @media (max-width: 480px) {
    width: 120px;
    height: 45px;
  }
`;

const LinkStyle = styled(Link)`
  color: white;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  width: 48%;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
    padding: 12px 18px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    padding: 10px 16px;
  }
`;

const Link1 = styled(LinkStyle)`
  margin-left: 10px;
  background-color: #4a90e2;

  &:hover {
    background-color: #357ab8;
  }

  &:active {
    background-color: #2c6092;
  }
`;

const Link2 = styled(LinkStyle)`
  background-color: #28a745;

  &:hover {
    background-color: #218838;
  }

  &:active {
    background-color: #1e7e34;
  }
`;
