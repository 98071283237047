import styled from "@emotion/styled";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const KakaoChatComponent: React.FC = () => {
  return (
    <Container>
      <a href="tel:031-711-1726">
        <ImgContainer>
          <IconSVG></IconSVG>
          <TextContainer>
            <TextComponent>{"031-711-1726"}</TextComponent>
          </TextContainer>
        </ImgContainer>
      </a>
      <a href="https://pf.kakao.com/_NCTxhn/chat" target="_blank" rel="noreferrer">
        <ImgContainer>
          <Image src="../images/kakaotalk.png"></Image>
          <TextContainer>
            <TextComponent>{"카카오톡상담"}</TextComponent>
          </TextContainer>
        </ImgContainer>
      </a>
    </Container>
  );
};

export default KakaoChatComponent;

const Container = styled.div`
    justify-items: center;
    position: fixed;
    top: 200px;
    right: 0;
    width: 120px;
    height: 220px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    z-index: 999;
    & > a {
      &:first-of-type {
        &::after {
          content: "";
          width: 100px;
          height: 1px;
          position: absolute;
          left: 50%;
          display: block;
          background-color: #e7e7e7;
          transform: translateX(-50%);
        }
      }
    }
    @media (max-width: 1024px) {
      width: 100px;
      height: 180px;
      & > a {
        &:first-of-type {
          &::after {
            content: "";
            width: 70px;
            height: 1px;
          }
        }
      }
    }
    @media (max-width: 768px) {
      width: 80px;
      height: 160px;
      & > a {
        &:first-of-type {
          &::after {
            content: "";
            width: 70px;
            height: 1px;
          }
        }
      }
    }
  `,
  ImgContainer = styled.div`
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    height: 50%;
    flex-direction: column;
    justify-content: space-evenly;
  `,
  IconSVG = styled(LocalPhoneIcon)`
    font-size: 8vh;
    display: block;
    margin: 0 auto;
    text-align: center;
    color: var(--color-hover);
    @media (max-width: 1024px) {
      font-size: 6vh;
    }
  `,
  Image = styled.img`
    width: auto;
    height: 8vh;
    display: block;
    margin: 0 auto;
    text-align: center;
    @media (max-width: 1024px) {
      height: 6vh;
    }
  `,
  TextContainer = styled.div`
    text-align: center;
    align-items: center;
  `,
  TextComponent = styled.span`
    position: relative;
    font-size: 1.1rem;
    line-height: 1.2;
    color: #000;
    font-weight: 600;
    @media (max-width: 1024px) {
      font-size: 0.8rem;
    }
    @media (max-width: 768px) {
      display: none;
    }
  `;
