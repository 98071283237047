import MenuComponent from "./menu";
import {
  InnerContainer,
  InnerContainer2,
  MainImg,
  MainInnerContainer,
  MainSection,
  MainTextContainer,
  MenuContainer,
  Section1,
  Section2,
  Section3,
  TextContainer,
} from "./style";
import SliderComponent from "./slider";
import GoalComponent from "./goal";
import styled from "@emotion/styled";
import ReportComponent from "./report";
import FooterComponent from "./Footer";
import SliderComponent2 from "./slider2";
import KakaoChatComponent from "../common/SideBar";
import { useNavigate } from "react-router-dom";

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  const handleFreeTrialClick = () => {
    navigate("/teacher/signup");
  };

  const assets = {
    images: [
      "/images/intro/image1.jpg", // 이미지 경로를 실제 이미지 경로로 변경 필요
      "/images/intro/image2.jpg",
      "/images/intro/image3.jpg",
      "/images/intro/image4.jpg",
      "/images/intro/image5.jpg",
      "/images/intro/image6.jpg",
    ],
    menus: [
      "무비보기", // 이미지 경로를 실제 이미지 경로로 변경 필요
      "쉐도잉",
      "더빙",
      "단어학습",
      "문장학습",
      "대화학습",
    ],
    texts: [
      "재미있는 애니메이션으로 수업을 시작해요",
      "문장을 따라 말해보세요",
      "무비속 주인공이 되어 리얼하게 더빙해보세요",
      "그림과 함께 단어를 쉽께 배워요",
      "배운단어로 문장 만들어 심화학습",
      "묻고 답하기를 통해 원어민 앞에서도 자신있게 말하기",
    ],
  };

  const assets2 = {
    images: ["/images/intro/tv1.png", "/images/intro/tv2.png", "/images/intro/tv3.jpg"],
  };

  return (
    <>
      <MenuContainer>
        <MenuComponent />
      </MenuContainer>
      <MainSection>
        <MainInnerContainer>
          <MainImg src="/images/intro/main.png" alt="대문" />
          <MainTextContainer>
            <MainText1>
              영상 더빙 <br />
              Role-Playing으로
            </MainText1>
            <MainText2>통문장 쉐도잉 영어</MainText2>
            <LinkButton onClick={handleFreeTrialClick}>15일 무료체험 하기</LinkButton>
          </MainTextContainer>
        </MainInnerContainer>
      </MainSection>
      <Section1>
        <InnerContainer>
          <GoalComponent />
        </InnerContainer>
      </Section1>
      <Section2>
        <InnerContainer>
          <TextContainer>
            <h1>200개 이상의 학습용 영상 콘텐츠</h1>
          </TextContainer>
          <img src="/images/intro/level.png" alt="컨텐츠" />
        </InnerContainer>
      </Section2>
      <Section1>
        <InnerContainer>
          <TextContainer>
            <h1>자기주도형 프로그램</h1>
          </TextContainer>
          <SliderComponent assets={assets} />
        </InnerContainer>
      </Section1>
      <Section2>
        <InnerContainer2>
          <ReportComponent />
        </InnerContainer2>
      </Section2>
      <Section3>
        <InnerContainer>
          <TextContainer>
            <h1>방송에 출연한 학생이 선택한 프로그램</h1>
          </TextContainer>
          <SliderComponent2 assets={assets2} />
        </InnerContainer>
      </Section3>
      <FooterComponent />
      <KakaoChatComponent />
    </>
  );
};

export default HomePage;

const MainText1 = styled.div`
  font-size: 65px;
  line-height: 90px;
  font-weight: 700;
  color: white;
  margin-bottom: 24px;
  word-break: keep-all;

  @media (max-width: 1024px) {
    font-size: 50px;
    line-height: 70px;
  }

  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 60px;
  }

  @media (max-width: 480px) {
    font-size: 30px;
    line-height: 45px;
  }
`;

const MainText2 = styled.div`
  font-size: 28px;
  line-height: 41px;
  font-weight: 600;
  color: white;
  margin-bottom: 40px;
  word-break: keep-all;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 35px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    line-height: 25px;
  }
`;

const LinkButton = styled.button`
  padding: 16px 32px;
  cursor: pointer;
  border-radius: 100px;
  color: white;
  font-size: 20px;
  line-height: 29px;
  font-weight: bold;
  background-color: #4a90e2; // 밝은 푸른색

  &:hover {
    background-color: #357ab8;
  }

  &:active {
    background-color: #2c6092;
  }

  @media (max-width: 1024px) {
    padding: 14px 28px;
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: 768px) {
    padding: 12px 24px;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 14px;
    line-height: 22px;
  }
`;
