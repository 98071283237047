import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import "@radix-ui/themes/styles.css";

interface Props {
  assets: Assets;
  autoSlideInterval?: number; // 슬라이드 자동 전환 간격 (밀리초)
}

interface Assets {
  images: string[];
}

const SliderComponent2: React.FC<Props> = React.memo(({ assets, autoSlideInterval = 3000 }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [fade, setFade] = useState(false);

  // 슬라이드 자동 전환 함수
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % assets.images.length);
        setFade(false);
      }, 300); // 페이드 아웃 후 이미지 변경
    }, autoSlideInterval);

    // cleanup: 컴포넌트가 언마운트되거나 다시 렌더링될 때 인터벌 제거
    return () => clearInterval(interval);
  }, [currentImage, assets.images.length, autoSlideInterval]);

  return (
    <Container>
      <ImageContainer className={fade ? "fade" : ""}>
        <img
          src={assets.images[currentImage]}
          alt="슬라이드 이미지"
          loading="lazy" // Lazy loading 추가
        />
      </ImageContainer>
    </Container>
  );
});

export default SliderComponent2;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

const ImageContainer = styled.div`
  background-color: #e5e5e5;
  width: 100%;
  /* height: 400px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease-in-out;
  }

  &.fade img {
    opacity: 0;
  }

  @media (max-width: 1024px) {
    width: 90%;
    height: 350px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
  }

  @media (max-width: 480px) {
    height: 200px;
  }
`;
