import React, { useState } from "react";
import styled from "@emotion/styled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useMediaQuery } from "@mui/material";

const GoalComponent: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 768px)"); // 반응형 디자인용

  const handlePlay = () => {
    setIsPlaying(true); // 비디오가 재생되면 문구를 숨김
  };

  return (
    <Container>
      <TextContainer>
        <TitleContainer>
          <h1>더빙 Role-playing으로 즐기는 영어</h1>
        </TitleContainer>
        <ContentContainer>
          {[
            "즐겁고 재미있는 영어 수업",
            "원어민식 발음, 억양, 표현법 모사",
            "생활 영어 그대로 통문장 쉐도잉",
          ].map((text, index) => (
            <FeatureItem key={index} text={text} />
          ))}
        </ContentContainer>
      </TextContainer>
      <VideoContainer>
        {!isPlaying && ( // 비디오 재생 중이 아닐 때만 문구 표시
          <OverlayText isSmallScreen={isSmallScreen}>
            학생이 직접 만든 더빙 영상을 보세요
          </OverlayText>
        )}
        <Video
          src="https://cnlrt5ox916.edge.naverncp.com/video/2.mp4"
          controls
          onPlay={handlePlay}
          aria-label="더빙 영상"
        />
      </VideoContainer>
    </Container>
  );
};

const FeatureItem: React.FC<{ text: string }> = ({ text }) => (
  <InlineContainer>
    <StyledCheckIcon />
    <Text>{text}</Text>
  </InlineContainer>
);

export default GoalComponent;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* 작은 화면에서는 컬럼 레이아웃을 사용 */
  align-items: center;
  padding: 20px 0;
  justify-content: space-evenly;
  @media (min-width: 769px) {
    flex-direction: row; /* 큰 화면에서는 로우 레이아웃 */
    gap: 40px;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  @media (min-width: 769px) {
    width: 70%;
  }
`;

const TitleContainer = styled.div`
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 600;
  word-break: keep-all;
  margin-bottom: 12px;
  text-align: center;
  @media (min-width: 769px) {
    text-align: left;
  }
`;

const ContentContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;
`;

const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StyledCheckIcon = styled(CheckCircleIcon)`
  font-size: 24px;
  margin-right: 16px;
  color: #4caf50; /* 초록색 아이콘 */
`;

const Text = styled.span`
  font-weight: 400;
  font-size: 1.5rem;
  color: #4c4e4c;
  word-break: keep-all;
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: 769px) {
    width: 70%;
  }
`;

const Video = styled.video`
  width: 100%;
`;

const OverlayText = styled.div<{ isSmallScreen: boolean }>`
  position: absolute;
  top: ${(props) => (props.isSmallScreen ? "5px" : "10px")};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: ${(props) => (props.isSmallScreen ? "1rem" : "1.25rem")};
  padding: 8px 16px;
  border-radius: 8px;
  text-align: center;
`;
