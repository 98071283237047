import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import "@radix-ui/themes/styles.css";
import { Button } from "@radix-ui/themes";

interface Props {
  assets: Assets;
  autoSlideInterval?: number; // 슬라이드 자동 전환 간격 (밀리초)
}

interface Assets {
  menus?: string[];
  images: string[];
  texts: string[];
}

const SliderComponent: React.FC<Props> = React.memo(({ assets, autoSlideInterval = 3000 }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [fade, setFade] = useState(false);

  // 슬라이드 자동 전환 함수
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % assets.images.length);
        setFade(false);
      }, 300); // 페이드 아웃 후 이미지 변경
    }, autoSlideInterval);

    // cleanup: 컴포넌트가 언마운트되거나 다시 렌더링될 때 인터벌 제거
    return () => clearInterval(interval);
  }, [currentImage, assets.images.length, autoSlideInterval]);

  // 버튼 클릭 시 슬라이드 변경
  const handleButtonClick = useCallback(
    (index: number) => {
      if (index !== currentImage) {
        setFade(true);
        setTimeout(() => {
          setCurrentImage(index);
          setFade(false);
        }, 300);
      }
    },
    [currentImage]
  );

  return (
    <Container>
      {assets.menus && (
        <ButtonContainer>
          {assets.menus.map((menu, index) => (
            <StyledButton
              key={index}
              variant="ghost"
              onClick={() => handleButtonClick(index)}
              className={currentImage === index ? "active" : ""}>
              {menu}
            </StyledButton>
          ))}
        </ButtonContainer>
      )}

      <ImageContainer className={fade ? "fade" : ""}>
        <img
          src={assets.images[currentImage]}
          alt="슬라이드 이미지"
          loading="lazy" // Lazy loading 추가
        />
      </ImageContainer>
      <TextH2>{assets.texts[currentImage]}</TextH2>
    </Container>
  );
});

export default SliderComponent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3개씩 배치 */
    align-items: center;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 8px;
  font-size: 1.2rem;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &.active {
    background-color: #0061f2;
    color: white;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 0.9rem;
  }
`;

const ImageContainer = styled.div`
  background-color: #e5e5e5;
  width: 80%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease-in-out;
  }

  &.fade img {
    opacity: 0;
  }

  @media (max-width: 1024px) {
    width: 90%;
    height: 350px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
  }

  @media (max-width: 480px) {
    height: 200px;
  }
`;

const TextH2 = styled.h2`
  margin-top: 3rem;
`;
